import React, { useEffect, useState } from 'react';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import BacgroundPattern from '../../assets/bg/backgroundv3.jpg'
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import {
    getLedgerWallet,
    getPhantomWallet,
    getSlopeWallet,
    getSolflareWallet,
    getSolletExtensionWallet,
    getSolletWallet,
    getTorusWallet,
} from '@solana/wallet-adapter-wallets';
import {
    WalletModalProvider,
    WalletDisconnectButton,
    WalletMultiButton
} from '@solana/wallet-adapter-react-ui';
// import { clusterApiUrl } from '@solana/web3.js';

import Modal from '@mui/material/Modal';
import { Box, ThemeProvider } from '@mui/system';
import { Button } from '@mui/material';
import "./Claims.css";
// import { Wasallet } from '../../components/Wallets/Wallets'
import { Connection, SystemProgram, Transaction, clusterApiUrl } from '@solana/web3.js';
import Wallet from '@project-serum/sol-wallet-adapter';


const Claims = () => {
    const { connection } = useConnection();
    // const { publicKey, sendTransaction } = useWallet();
    const { wallet, connect, connecting, connected, publicKey, sendTransaction } = useWallet();
    const [isConnected, setIsConnected]=useState(false);
    const [walletType, setWalletType]=useState(null);

    const [isWalletSelect, setIsWalletSelect]= useState(false);

    const [solletWallet, setSolletWallet]= useState(null);
    console.log('connection', connection)
    console.log('publicKey', publicKey)
    console.log('publicKey', publicKey)
    const { innerWidth: width, innerHeight: height } = window;

    const MODAL_WIDTH = height * .8;
    useEffect(() => {
        // window.solana.connect();
        // window.solana.on("connect", () => console.log("connected!"))
        // connect().catch(() => { });
    },[])

    const connectPhantomWallet = () => {
        try {
            window.solana.connect();
            window.solana.on("connect", () => {
                console.log("connected!", window.solana.isConnected, window.solana.publicKey.toString())
                
                setIsConnected(true);
                setWalletType('phantom')
            })
            connect().catch(() => { });
        } catch (error) {
            
        }
    }

    const disconnectPhantomWallet = () => {
        window.solana.disconnect();       
        window.solana.request({ method: "disconnect" });
    }

    const disconnectSolanaWallet = () => {
        switch(walletType){
            case 'phantom':{
                window.solana.disconnect();
                break
            }
            case 'sollet':{
                window.solana.disconnect();
                break
            }
            case 'solflare':{
                break
            }
        }
        
        window.solana.on('disconnect', () => {
            console.log("disconnected!",window.solana.isConnected)
            setIsConnected(false);
            setWalletType(null)
        })

        // window.solana.request({ method: "disconnect" });

    }


    const connectSolletWallet = async() => {

        let provider = 'https://www.sollet.io';

        // For sollet extension use
        // provider = window.sollet

        let wallet = new Wallet(provider);
        wallet.on('connect', publicKey => {
            console.log('Connected to SOllet ' + publicKey.toBase58())
            setWalletType('sollet')
            setSolletWallet(wallet)
        });
        wallet.on('disconnect', () => console.log('Disconnected'));
        await wallet.connect();

        // Sending a transaction
        let connection = new Connection(clusterApiUrl('devnet'));

        // let transaction = new Transaction().add(
        // SystemProgram.transfer({
        //     fromPubkey: wallet.publicKey,
        //     toPubkey: wallet.publicKey,
        //     lamports: 100,
        // })
        // );
        // let { blockhash } = await connection.getRecentBlockhash();
        // transaction.recentBlockhash = blockhash;
        // transaction.feePayer = wallet.publicKey;
        // let signed = await wallet.signTransaction(transaction);
        // let txid = await connection.sendRawTransaction(signed.serialize());
        // await connection.confirmTransaction(txid);

        // Phantom Wallet
        // window.solana.connect();
        // window.solana.on("connect", () => console.log("connected!"))

        // console.log(window.solana.publicKey);
    }

    const connectSolflareWallet = async() => {
        let connection = new Connection(clusterApiUrl('devnet'));
        let providerUrl = 'https://solflare.com/provider';
        let wallet = new Wallet(providerUrl);
        wallet.on('connect', publicKey => {
            console.log('Connected to ' + publicKey.toBase58())
            setWalletType('solflare')
        });
        wallet.on('disconnect', () => console.log('Disconnected'));
        await wallet.connect();

        // let transaction = new Transaction().add(
        //     SystemProgram.transfer({
        //         fromPubkey: wallet.publicKey,
        //         toPubkey: wallet.publicKey,
        //         lamports: 100,
        //     })
        // );
        // let { blockhash } = await connection.getRecentBlockhash();
        // transaction.recentBlockhash = blockhash;
        // transaction.feePayer = wallet.publicKey;
        // let signed = await wallet.signTransaction(transaction);
        // let txid = await connection.sendRawTransaction(signed.serialize());
        // await connection.confirmTransaction(txid);
    }


    return(
        <div
            style={{
                height: '100vh',
                width:'100%',
                // backgroundColor:'red',
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
                // backgroundImage: `url("${BacgroundPattern}")`
            }}
            className="backgroundContainer"
        >

            <img 
                src={BacgroundPattern}
                style={{
                    position:'absolute',
                    height:'100%',
                    width:'100%'
                }}
            />

            {
                isWalletSelect&&
                    <div
                        style={{
                            position:'absolute',
                            height:'100vh',
                            width:'100vw',
                            display:'flex',
                            justifyContent:'center',
                            alignItems:'center',
                            top:0,
                            zIndex:1000,
                            backgroundColor:'#262626A8'
                        }}
                        onClick={() => setIsWalletSelect(false)}
                    >
                        <div
                            style={{
                                height:'50%',
                                width:300,
                                backgroundColor:'white',
                                flexDirection:'column',
                                display:'flex'
                            }}
                        >
                            Select Your Wallet
                            <Button 
                                size="large" 
                                variant="contained"
                                style={{
                                    flex:1,
                                    // height:'100%',
                                    padding:10,
                                    // backgroundColor:'crimson'
                                    // width:200
                                }}
                                onClick={() => connectSolletWallet()}

                                
                            >
                                <p
                                    style={{
                                        fontFamily:'Bungee',
                                        fontSize:15,
                                        fontWeight:'bolder'
                                    }}
                                >
                                    Sollet
                                </p>
                                
                            </Button>
                            <Button 
                                size="large" 
                                variant="contained"
                                style={{
                                    flex:1,
                                    // height:'100%',
                                    padding:10,
                                    // backgroundColor:'crimson'
                                    // width:200
                                }}
                                onClick={() => connectSolflareWallet()}

                                
                            >
                                <p
                                    style={{
                                        fontFamily:'Bungee',
                                        fontSize:15,
                                        fontWeight:'bolder'
                                    }}
                                >
                                    Solflare
                                </p>
                                
                            </Button>
                            <Button 
                                size="large" 
                                variant="contained"
                                style={{
                                    flex:1,
                                    // height:'100%',
                                    padding:10,
                                    // backgroundColor:'crimson'
                                    // width:200
                                }}
                                onClick={() => connectPhantomWallet()}

                                
                            >
                                <p
                                    style={{
                                        fontFamily:'Bungee',
                                        fontSize:15,
                                        fontWeight:'bolder'
                                    }}
                                >
                                    Phantom
                                </p>
                                
                            </Button>

                        </div>

                    </div>
            }
            

            <div
                style={{
                    height:MODAL_WIDTH,
                    width:MODAL_WIDTH,
                    backgroundColor:'silver',
                    borderRadius:20,
                    display:'flex',
                    flexDirection:'column',
                    zIndex:1

                }}
            >

                <div
                    style={{
                        flex:9
                    }}
                >

                </div>
                <div
                    style={{
                        flex:1,
                        justifyContent:'center',
                        alignItems:'center',
                        display:'flex',
                        padding:20
                    }}
                >
                    {
                        !isConnected ?
                            <Button 
                                size="large" 
                                variant="contained"
                                style={{
                                    height:'100%',
                                    padding:10,
                                    backgroundColor:'crimson'
                                    // width:200
                                }}
                                onClick={() => setIsWalletSelect(true)}

                                
                            >
                                <p
                                    style={{
                                        fontFamily:'Bungee',
                                        fontSize:15,
                                        fontWeight:'bolder'
                                    }}
                                >
                                    Connect Wallet
                                </p>
                                
                            </Button> :

                            <Button 
                                size="large" 
                                variant="contained"
                                style={{
                                    height:'100%',
                                    padding:10,
                                    backgroundColor:'crimson'
                                    // width:200
                                }}
                                onClick={() => disconnectSolanaWallet()}


                            >
                                <p
                                    style={{
                                        fontFamily:'Bungee',
                                        fontSize:15,
                                        fontWeight:'bolder'
                                    }}
                                >
                                    Disconnect Wallet
                                </p>

                            </Button>

                    }
                    


                </div>

            </div>
            {/* <Wallet/> */}
        </div>
    )
}

export default Claims;