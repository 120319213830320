import React, { useRef, useEffect, useState } from 'react';
import { AppBar, Alert } from '@mui/material';
import { useHistory } from "react-router-dom";

import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import { alpha, Timeline } from '@mui/lab';
import Slide from '@mui/material/Slide';
import Zoom from '@mui/material/Zoom';
import Box from '@mui/material/Box';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Fab from '@mui/material/Fab';
import Slider from "react-slick";
import { Snackbar } from '@mui/material';

import LandingVideo from '../../assets/videos/3dLanding.mp4'
import PartnerVideo from '../../assets/videos/partnerv5.mp4'
import SkateBanner from '../../assets/banners/skatebanner.jpeg'
import TestBanner from '../../assets/banners/testbanner.png'
import TestBanner1400 from '../../assets/banners/testbanner1400.png'
import Svg0 from '../../assets/banners/svg5.svg'

import ColLogo from '../../assets/logo/collogo1.png'

import Gift from '../../assets/icons/gift.png'
import Arcade from '../../assets/icons/arcade.png'
import Love from '../../assets/icons/love.png'
import Rocket from '../../assets/icons/rocket.png'
import Camp from '../../assets/icons/camp.png'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Landing.css"
import { Gif, Translate } from '@mui/icons-material';
const Landing = () => {
    const history = useHistory();

    const [dropNav, setDropNav]= useState(false);
    const [error, setError]= useState(null);
    const scrollRef = useRef(null)

    const onScroll = (e) => {
        const scrollY = window.scrollY 
        const scrollTop = scrollRef.current.scrollTop

        if(!dropNav&&Number(scrollTop)>45){
            setDropNav(true)
        }else if(dropNav&&Number(scrollTop)<45){
            setDropNav(false)
        }
    }


    return (
        <div
            style={{
                height:'100vh',
                width:'100%',
                backgroundColor:'white',
                overflowY:'auto'
            }}
            ref={scrollRef}
            onScroll={onScroll}
        >
            <HideOnScroll
                dropNav={dropNav}
            >
                <AppBar
                    style={{
                        backgroundColor:'black',
                        justifyContent:'center',
                        display:'flex',
                    }}
                    className="appBar"
                >
                    <Toolbar>
                        <p
                            style={{
                                fontFamily:'Bungee',
                                fontWeight:'bolder',
                                color:'white'
                            }}
                            className="navTitle"
                        >
                            Collectibulls
                        </p>
                        
                    </Toolbar>
                </AppBar>
            </HideOnScroll>
            <div  style={{height:1}}id="back-to-top-anchor" />
             
                <div
                    style={{
                        width:'100%',
                        backgroundColor:'#1a1a1a',
                        display:'flex',
                        flexDirection:'column',
                        position:'relative'
                    }}
                >
                    <div
                        style={{
                            backgroundColor:'#1a1a1a'
                        }}
                        className="appBar"
                        id="spacer1"
                    >

                    </div>
                    <div
                        style={{
                            position:'absolute',
                            top:0,
                            left:0,
                            // backgroundColor:'#0000004D',
                            display:'flex',
                            flexDirection:'column'
                        }}
                        className="landingOverlay"
                    >
                        <div
                            style={{
                                height:'100%',
                                width:'100%',
                                display:'flex',
                                flexDirection:'row',
                                justifyContent:'center',
                                alignItems:'center'
                            }}
                        >
                            <button 
                                style={{
                                    cursor:'pointer', 
                                    zIndex:100,
                                    fontFamily:'Bungee'
                                }} 
                                className="fancyButton1"
                                onClick={() => setError('Mint comming soon :D')}
                            >
                                Mint
                            </button>
                            <button 
                                style={{
                                    cursor:'pointer', 
                                    zIndex:100,
                                    fontFamily:'Bungee',
                                    marginLeft:10,
                                    color:'black'
                                }} 
                                className="fancyButton4"
                                onClick={() => history.push('/about')}
                            >
                                About
                            </button>
                        </div>
                        
                    </div>

                    <video 
                        autoPlay
                        muted
                        loop
                        id="myVideo"
                          className="landingVideo"
                    >
                    <source src={PartnerVideo} type="video/mp4"/>
                        Your browser does not support HTML5 video.
                    </video>


                </div>
                {/* <TimeLine/> */}
                <div
                    style={{
                        width:'100%',
                        overflowX:'hidden',
                        backgroundColor:'white',
                        margin:0
                    }}
                    className="timelineOuter"
                >
                    <div
                        style={{
                            fontSize:40,
                            padding: (0, 20, 0, 20)
                        }}
                    >
                        <h1
                            className="roadMapTitle"
                        >
                            Roadmap
                        </h1>
                        
                    </div>
                    
                    <TimeLine2/>
                </div>

            <div
                style={{
                    width:'100%',
                    backgroundColor:'black',
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                    paddingTop: 50,
                    cursor:'pointer'
                    
                }}

                onClick={() => window.open('https://farskate.co.uk')}
            >

                <img 
                    src={SkateBanner} 
                    className="skateBanner"
                />

            </div>

            <div
                style={{
                    height:200,
                    width:'100%',
                    backgroundColor:'black',
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                    color:'silver'
                }}
            >
                <p
                    style={{
                        fontSize:12,
                        color:'grey'
                    }}
                >
                    CollectiBulls 2021
                </p>
            </div>
                

            <ScrollTop 
                dropNav={dropNav}
            >
                <Fab 
                    color="secondary" 
                    size="small" 
                    aria-label="scroll back to top"
                    style={{
                        backgroundColor:'white',
                        zIndex:100
                    }}
                    
                >
                    <KeyboardArrowUpIcon style={{color:'black'}}/>
                </Fab>
            </ScrollTop>
            <Snackbar
                open={error}
                autoHideDuration={10000}
                onClose={() => setError(null)}
                message={error}
                action={() => setError(null)}
                style={{
                    width:'25%',
                    alignSelf:'center'
                }}
            >
                <Alert onClose={() => setError(null)} severity="error" sx={{ width: '100%' }}>
                    {error}
                </Alert>
            </Snackbar>
        </div>
    )
}

const HideOnScroll = ({children, dropNav}) => {
    return (
        <Slide 
            appear={!dropNav} 
            direction="down" 
            in={!dropNav}

        >
            {children}
        </Slide>
    );
}

const ScrollTop = ({children,dropNav }) => {

    const handleClick = (event) => {
      const anchor = (event.target.ownerDocument || document).querySelector(
        '#back-to-top-anchor',
      );
  
      if (anchor) {
        anchor.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    };
  
    return (
        <Zoom 
            in={dropNav}
            style={{
                zIndex:100
            }}
        >
            <Box
            onClick={handleClick}
            role="presentation"
            sx={{ position: 'fixed', bottom: 16, right: 25 }}
            >
            {children}
            </Box>
        </Zoom>
    );
}


const TimeLine2 = () => {


    const getSummary = (sumType) => {
        switch(sumType){
            case 'launch':{
                return(
                    <p
                        className="timeCardSummary"
                    >
                        Launch Day we will be continuing to roll out of marketing campaign and building more partnerships in the NFT and sports world.                  
                    </p>
                )
            }
            case 'charity':{
                return(
                    <p
                        className="timeCardSummary"
                
                    >
                        'CollectiBulls' has collaborated with the Far Skate Foundation <a style = {{whiteSpace:'nowrap'}} href="https://farskate.co.uk">https://farskate.co.uk/</a>. 
                         Far Skate is a youth development charity working directly with youngsters and providing them a safe environment to be kids for as long as possible and have 
                        some adrenaline fun at the same time. Along with this, they also help nurture those same children post 16yrs of age and assist them setting up their own businesses to prepare for their future. 
                        Possible other charities involved. We will be continuing with giveaways and competitions for our holders, why? Because the herd looks after each other!                  
                    </p>
                )
            }
            case 'evo':{
                return(
                    <p
                        className="timeCardSummary"
                    >
                        As the Collectibulls continue to evolve we will reveal a surprise for our holders. This is something EXTREMELY exciting we are working on and although we can’t give too much information about it right now, when we say you’ll be excited about this ‘evolution’ we REALLY mean it!
                    </p>
                )
            }
            case 'arcade':{
                return(
                    <p
                        className="timeCardSummary"
                    >
                        <div style={{float:"left"}}>
                        • Amazing 3D Game 
                        </div><br/>
                        <div style={{float:"left"}}>
                        We’ll be offering the ability to transform your NFT into a playable 3D game character that can compete against other players in various high adrenaline sports. This will include competitive events too!
                        </div><br/><p/>
                        
                    </p>
                )
            }
            case 'arcade':{
                return(
                    <p
                        className="timeCardSummary"
                    >
                        Game development. A game will be coming for all our members to play and to play against each other, we will be running special events with special guests and did someone say skate competition? Further charity involvement and continued collaboration with real world application of the ‘CollectiBulls’ community. We will never reach 100% because we will be continuing to develop new and exciting things for our community.
                    </p>
                )
            }
            case 'future':{
                return(
                    <p
                        className="timeCardSummary"
                    >
                        <div style={{float:"left"}}>
                        • Further Charity Outreach
                        </div><br/>
                        <div style={{float:"left"}}>
                         As part of our projects foundational core, we are devoted to finding and supporting good causes. We are dedicated to this and will continue to search for and work with charities and community organizations, offering them ongoing support and assistance.
                        </div>
                        {/* <div style={{ height:10, width:'100%'}}/> */}

                        <div style={{float:"left", marginTop: 5}}>
                        • The Continued Expansion of Collectibulls
                        </div><br/>
                        <div style={{float:"left"}}>
                        As part of the project’s ‘Evolution’ initiative, we will continue to build out the Collectibulls world. So grab your bulls tight, because if you do we’re gonna reward you for it!
                        </div><br/><p/>
                    </p>
                )
            }
        }

    }


    const data = [
        {
            title:'Launch',
            info: getSummary('launch'),
            icon: Camp,
            quarter: 'Q4-Q1',
            percent: '10% Sold'
        },
        {
            title:'Charity',
            info: getSummary('charity'),
            icon: Love,
            quarter: 'Q4-Q1',
            percent: '20-60% Sold'
        },
        {
            title:'Evolution',
            info: getSummary('evo'),
            icon: Gift,
            quarter: 'Q1-Q2',
            percent: '60-90% Sold'
        },
        {
            title:'Arcade Game',
            info: getSummary('arcade'),
            icon: Arcade,
            quarter: 'Q2-Q3',
            percent: '90-101% Sold'
        },
        {
            title:'Future',
            info: getSummary('future'),
            icon: Rocket,
            quarter: '?',
            percent: 'Sold Out'
        }

  
    ]

    const [ww, setww]=useState(window.innerWidth)

    const [slidesScroll, setSlidsScroll]=useState(1)
    const [slidsesShow, setSlidesShow]=useState(2)
    const [lol, setLol]=useState(false)


    useEffect(() => {
        window.addEventListener('resize', () => {
            setww(window.innerWidth)
            if(window.innerWidth <= 900){
                setSlidesShow(1)
                setSlidsScroll(1)
            }else{
                setSlidesShow(2)
                setSlidsScroll(1) 
            }
        })
        setTimeout(() => {
            setLol(true)
            if(window.innerWidth <= 900){
                setSlidesShow(1)
                setSlidsScroll(1)
            }else{
                setSlidesShow(2)
                setSlidsScroll(1) 
            }
        }, 1000)
    },[])
    let settings = {
        dots: true,
        infinite: false,
        speed: 600,
        slidesToShow: slidsesShow,
        slidesToScroll: slidesScroll,
        initialSlide: 0,
      };

    return (
        <div
            style={{
                position:'relative',
                width:'100%',
            }}
        >
            <Slider 
                {...settings}
                style={{
                    zIndex:10,
                    justifyContent:'center',
                    display:'flex',
                    alignItems:'center',
                }}
            >
                {
                    data.map(eventObj => {
                        return(
                            // console.log(eventObj.info())
                            <div
                                style={{
                                    paddingTop:50,
                                    paddingBottom:50,
                                    display:'flex'
                                }}
                                id="outer"
                            >
                                <div
                                    className='card2 move-up timeCardInner'
                                >
                                    <div
                                        style={{
                                            justifyContent:'center',
                                            alignItems:'center',
                                            display:'flex',
                                        }}
                                        className="timeCardHeader"
                                    >
                                        <img 
                                            src={eventObj.icon} 
                                            className="timeCardImage"
                                        />
     
                                        <h3
                                            className="timeCardTitle"
                                        >
                                            
                                            {eventObj.title}
                                        </h3>

                                    </div>
                                    <div
                                        className="timeCardSummarConatainer"
                                    >
                                        {/* <p
                                            className="timeCardSummary"
                                        > */}
                                            {eventObj.info}
                                        {/* </p> */}
                                    </div>
                                    
                                    <div
                                        style={{
                                            display:'flex',
                                            flexDirection:'column',
                                        }}
                                    >
                                        <div 
                                            style={{
                                                zIndex:100,
                                                fontFamily:'Bungee',
                                                alignSelf:'center',
                                                alignItems:'center',
                                                justifyContent:'center',
                                                display:'flex'
                                            }} 
                                            className="cardFlash"
                                        >
                                            {eventObj.quarter}
                                        </div>
                                        <div 
                                            style={{
                                                zIndex:100,
                                                fontFamily:'Bungee',
                                                alignSelf:'center',
                                                marginTop:10,
                                                alignItems:'center',
                                                justifyContent:'center',
                                                display:'flex'
                                            }} 
                                            className="cardFlash1"
                                        >
                                            {eventObj.percent}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </Slider>
            <hr 
                class="dashed-2"
                style={{
                    position:'absolute',
                    zIndex:1
                }}
                className="dashed-2"
            ></hr>

        </div>
    )
}
  
export default Landing;